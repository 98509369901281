<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('details') }}</h3>
  </div>
  <spinner-component v-if="!license" item-class="spinner-layout-gray"></spinner-component>
  <div v-if="license">
    <license-statistic v-if="license" :license="license"></license-statistic>
    <div
      class="task-detail-right"
    >
      <div class="card">
        <div class="card-header">
          <h5>{{ $t('licenseInformation') }}</h5>
        </div>
        <div class="card-block task-details">
          <table class="table">
            <tbody>
              <tr>
                <td><i class="feather icon-bar-chart-2 m-r-5"></i>{{ $t('accountStatus') }}:</td>
                <td class="text-right" v-if="license.status !== 'ACTIVE'"><b>{{ $t(`licenseStatuses.${license.status}`) }}</b></td>
                <td class="text-right" v-if="license.status === 'ACTIVE'">{{ $t(`licenseStatuses.${license.status}`) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="table-separator"></div>
          <table class="table">
            <tbody>
              <tr v-if="license.company_name">
                <td><i class="feather icon-box m-r-5"></i> {{ $t('companyName') }}:</td>
                <td class="text-right">{{ license.company_name }}</td>
              </tr>
              <tr v-if="license.company_site">
                <td><i class="feather icon-link m-r-5"></i> {{ $t('companySite') }}:</td>
                <td class="text-right">{{ license.company_site }}</td>
              </tr>
              <tr v-if="license.phone_number">
                <td><i class="feather icon-phone m-r-5"></i> {{ $t('phoneNumber') }}:</td>
                <td class="text-right">
                  {{ license.phone_number }}
                  <div v-if="(typeof license.phone_number_verified === 'boolean' && license.phone_number_verified === false) && !phoneJustVerified">
                    <div class="font-weight-bold" v-if="license.phone_number_verify_requests > 0">
                      {{$tc('forms.verifyPhone.ask_on_details', license.phone_number_verify_requests, { requests: license.phone_number_verify_requests })}}
                    </div>
                    <button @click="openVerification" class="btn btn-success mt-2 px-4">{{$t('forms.verifyPhone.verify')}}</button>
                  </div>
                </td>
              </tr>
              <tr v-if="license.first_name || license.last_name">
                <td><i class="feather icon-user m-r-5"></i> {{ $t('name') }}:</td>
                <td class="text-right"><span v-if="license.first_name">{{ license.first_name }}</span> <span v-if="license.last_name">{{ license.last_name }}</span></td>
              </tr>
              <tr v-if="license.country">
                <td><i class="feather icon-globe m-r-5"></i> {{ $t('accountCountry') }}:</td>
                <td class="text-right">{{ license.country }}<span v-if="license.postal_code">, {{ license.postal_code }}</span></td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12">
              <p>{{ $t('companyInfoText_short') }} <a href="#" @click.prevent="openChat">{{ $t('companyInfoLink') }}</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <license-detail-preferences
      v-if="license && license.email_preferences"
      :license="license"
    ></license-detail-preferences>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'

export default {
  name: 'DetailsPage',
  components: {
    'license-statistic': defineAsyncComponent(() => import('@/application/components/elements/LicenseStatistic.vue')),
    'license-detail-preferences': defineAsyncComponent(() => import('@/application/components/elements/LicenseDetailPreferences.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const phoneJustVerified = computed(() => store.getters.getVerified)
    const openChat = () => {
      window.$crisp.push(['do', 'chat:open'])
    }

    return {
      license,
      phoneJustVerified,
      openChat,
      openVerification: () => {
        store.dispatch('openVerification')
      },
    }
  },
}
</script>
